import React from 'react';
import BaseRender from './_baseRender'


const Test = (props) => (
    <>
        <p>Do not delete me!!</p>

    </>
);
export default Test;